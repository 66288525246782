<template>
  <div class="InputMonitorUrl">
    <div ref="protocol"
         class="protocol-container"
         @click="onProtocolClick">
      <div class="protocol">
        <div class="value">{{ protocolLabel }}</div>
        <svg data-v-223dc29e="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" class="icon-dropdown"><path opacity="0.7" d="M5 8L10 13L15 8" stroke="black" stroke-linecap="round" stroke-linejoin="round" fill="none"></path></svg>
      </div>
      <div class="line"></div>
    </div>

    <base-textbox ref="input"
                  class="url m-0"
                  :value="url"
                  :rules="rules"
                  placeholder="my-awesome-site.com"
                  :bordered="false"
                  @input="onInput" />
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  props: {
    value: {
      required: true
    },

    label: {
      required: false,
      type: String
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    rules: {
      required: false
    }
  },

  data () {
    return {
      url: '',
      protocol: 'https',

      tippy: null
    }
  },

  created () {
    this.url = this.value

    this.stripProtocol()
  },

  mounted () {
    this.updateTippy()
  },

  methods: {
    onInput (value) {
      this.emitValue(value)
    },

    onProtocolClick () {
      if (this.protocol === 'http') {
        this.protocol = 'https'
      } else {
        this.protocol = 'http'
      }

      this.emitValue(this.url)
      this.updateTippy()
    },

    emitValue (value) {
      // Do not prepend the protocol when the value is empty
      if (value === '') {
        this.$emit('input', '')
        return
      }

      // Do not prepend the protocol when the value already contains one
      if (value.substring(0, 7) === 'http://' ||
          value.substring(0, 8) === 'https://') {
        this.$emit('input', value)
        return
      }

      this.$emit('input', this.protocol + '://' + value)
    },

    updateTippy () {
      if (this.tippy) {
        this.tippy.destroy()
      }

      if (this.protocol === 'http') {
        this.tippy = tippy(this.$refs.protocol, {
          content: 'Switch to https',
          placement: 'bottom'
        })
      } else {
        this.tippy = tippy(this.$refs.protocol, {
          content: 'Switch to http',
          placement: 'bottom'
        })
      }
    },

    // onPaste (event) {
    //   this.url = (event.originalEvent || event).clipboardData.getData('text/plain')
    //
    //   this.stripProtocol()
    //   this.emitValue(this.url)
    // },

    stripProtocol () {
      if (this.url === 'https://' || this.url === 'http://') {
        return
      }

      if (this.url.substring(0, 7) === 'http://') {
        this.protocol = 'http'
        this.url = this.url.replace(/(^\w+:|^)\/\//, '')
      }

      if (this.url.substring(0, 8) === 'https://') {
        this.protocol = 'https'
        this.url = this.url.substr(8)
      }
    },

    focus () {
      this.$refs.input.focus()
    }
  },

  computed: {
    protocolLabel () {
      return this.protocol + '://'
    }
  },

  watch: {
    value (newValue) {
      this.url = newValue.trim()

      this.stripProtocol()
    }
  }
}
</script>

<style lang="scss" scoped>
  .InputMonitorUrl {
    position: relative;

    .protocol-container {
      @include flex-center;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .protocol {
        display: flex;
        align-items: center;

        width: 93px;
        height: 40px;
        padding: 0 14px;
        font-size: 14px;
        font-weight: 500;

        cursor: pointer;
        user-select: none;

        color: #797b83;

        &:hover {
          color: map-get($colors, purple-1);

          .value {
            // border-bottom: 1px dashed map-get($colors, purple-1);
          }
        }

        svg {
          position: absolute;
          right: 10px;
        }
      }

      .value {
        text-align: left;
        padding-bottom: 1px;
        // border-bottom: 1px dashed #999ca7;
      }

      .line {
        border-right: 1px solid map-get($colors, gray-4);
        width: 1px;
        height: 26px;
      }
    }

    .url ::v-deep {
      .input {
        padding: 0 10px 0 105px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
</style>
